// gatsby-browser.mjs  (note the .mjs extension)
import './src/styles/global.css';
import './src/styles/webfonts.css';
import './src/styles/plyr.css';

export const onClientEntry = () => {
  if (typeof window !== "undefined" && !window.widgetCSSLoaded) {
    const link = document.createElement("link");
    link.href = "https://lineuppolo-docs-prod.s3.eu-west-2.amazonaws.com/widget/styles/matchWidgetStyles.css";
    link.rel = "stylesheet";
    document.head.appendChild(link);
    window.widgetCSSLoaded = true;

    const linkTournaments = document.createElement("link");
    linkTournaments.href = "https://lineuppolo-docs-prod.s3.eu-west-2.amazonaws.com/widget/tournamentWidget/tournamentWidgetStyles.css";
    linkTournaments.rel = "stylesheet";
    document.head.appendChild(linkTournaments);
  }
}

export const shouldUpdateScroll = () => {
  if (typeof window !== "undefined") {
    window.scrollTo(0, 0);
  }
  return false;
}

export const onRouteUpdate = ({ location }) => {
  if (typeof window === 'undefined') return;
  
  if (location.hash) {
    const hash = location.hash.substring(1); // remove the #
    
    // Check if this is our special case with offset
    if (hash.includes('our-team-')) {
      const offset = parseInt(hash.split('-').pop(), 300);
      const baseId = hash.replace(`-${offset}`, '');
      
      setTimeout(() => {
        const element = document.getElementById(baseId);
        if (element) {
          const y = element.getBoundingClientRect().top + window.pageYOffset - offset;
          window.scrollTo({ top: y, behavior: 'instant' });
        }
      }, 100);
    } else {
      sessionStorage.setItem('scrollTarget', location.hash);
    }
  }
};